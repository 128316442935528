import React from 'react';
import { shadeColor } from '../../utils/shadeColor';
import { StyledBadge } from './styles';
import { BadgeSize } from '../../types/BadgeSize';

type BadgeProps = {
  label: string;
  backgroundColor: string;
  isGradient?: boolean;
  size?: BadgeSize;
};

export const Badge: React.FC<BadgeProps> = ({
  label,
  backgroundColor,
  isGradient = false,
  size = 'medium',
}) => {
  const backgroundStyle = isGradient
    ? `linear-gradient(135deg, ${backgroundColor}, ${shadeColor(
        backgroundColor,
        -20,
      )})`
    : backgroundColor;

  return (
    <StyledBadge background={backgroundStyle} size={size}>
      {label?.toUpperCase()}
    </StyledBadge>
  );
};
