import React, { useCallback, useState } from 'react';
import { MdOutlineCurrencyExchange } from 'react-icons/md';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderScreen } from '../HeaderScreen';
import { DadosCliente } from '../DadosCliente';
import { MudarDadosCobranca } from './styles';
import { ScreenName } from '../ScreenName';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import api from '~/services/api';
import { toast } from 'react-toastify';
import reset from '../DadosCliente/reset.json';

export const ChangeBillingDetails: React.FC = () => {
  const {
    screen,
    formClienteContratacao,
    handleScreen,
    setCustomerDataToForm,
    isDemonstracao,
    refetchCustomer,
  } = useMeuPlano();
  const {
    getValuesClienteContratacao,
    triggerClienteContratacao,
    resetClienteContratacao,
  } = formClienteContratacao;
  const [showLoading, setShowLoading] = useState(false);

  const validaDadosCliente = useCallback(async (): Promise<boolean> => {
    // Dispara a validação de todo o formulário de cliente
    const isValid = await triggerClienteContratacao();

    if (!isValid) {
      return false;
    }
    return true;
  }, [triggerClienteContratacao]);

  const handleChangeBillingDetails = useCallback(async () => {
    try {
      setShowLoading(true);
      if (!(await validaDadosCliente())) {
        return;
      }
      const {
        des_nome,
        des_email,
        num_cpf_cnpj,
        num_telefone,
        num_cep,
        des_rua,
        des_numero,
        des_complemento,
        des_bairro,
        des_cidade,
      } = getValuesClienteContratacao();

      const payload = {
        nome_pessoa: des_nome,
        num_cpf_cnpj,
        email: des_email,
        num_telefone,
        num_celular: num_telefone,
        des_logradouro: des_rua,
        num_endereco: des_numero,
        des_complemento,
        des_bairro,
        num_cep,
        num_ie: '',
        des_empresa_trab: '',
        cidade: des_cidade,
      };

      const response = await api.put('/meu-plano/update-customer', payload);

      if (!response.data.success) {
        toast.error('Falha ao atualizar dados de cobrança.');
        return;
      }
      refetchCustomer();
      toast.success('Dados de cobrança atualizados com sucesso');
      resetClienteContratacao(reset);
      setCustomerDataToForm();
      handleScreen('HOME');
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  }, [
    validaDadosCliente,
    getValuesClienteContratacao,
    refetchCustomer,
    resetClienteContratacao,
    setCustomerDataToForm,
    handleScreen,
  ]);

  if (screen === 'HOME') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          cursor: `${isDemonstracao ? 'not-allowed' : 'pointer'}`,
        }}
      >
        <MdOutlineCurrencyExchange
          size={130}
          color={`${isDemonstracao ? '#9c9c9c' : '#007BFF'}`}
        />
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          }}
        >
          Mudar dados de cobrança
        </p>
      </div>
    );
  }

  return (
    <MudarDadosCobranca>
      <HeaderScreen showButtonVoltar={screen === 'MUDAR-DADOS-COBRANCA'} />
      <ScreenName name="Dados de cobrança" />
      <div className="content">
        <DadosCliente />
        <div className="button">
          <CustomButtonNovo
            disabled={showLoading}
            onClick={async () => {
              await handleChangeBillingDetails();
            }}
            label="Mudar dados de cobrança"
            width="14.375rem"
            showLoadingIcon={showLoading}
          />
        </div>
      </div>
    </MudarDadosCobranca>
  );
};
